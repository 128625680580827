<template>
  <b-card
  >
    <div class="m-2">
      <b-overlay
          :show="show"
          no-wrap
      />
      <b-row>
        <b-col
            style="margin-top: 20px"
            cols="12"
        >
          <a href="/nueva_etiqueta">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Agregar Etiqueta
            </b-button>
          </a>
        </b-col>
        <b-col
            style="margin-top: 20px"
            cols="12" lg="6"
        >
          <b-form-group
              label="Producto"

          >
            <v-select
                style=" border-radius: 1px !important;"
                v-model="filter_product_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                @input="searchByProduct()"
                :options="optionsProducts"
            />
          </b-form-group>
        </b-col>

        <b-col
            style="margin-top: 20px"
            cols="12" lg="6"
        >
          <b-form-group
              label="Marca"

          >
            <b-input-group size="md">
              <b-form-input
                  id="basicInput"
                  type="search"
                  @search="resetFilterBrand"
                  @keyup="searchForBrand"
                  v-model="brand"
                  placeholder="Buscar"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
            style="margin-top: 10px"
            cols="12" lg="6"
        >
        </b-col>
        <b-col
            style="margin-top: 10px"
            cols="12" lg="6"
        >
          <b-form-group
              label="Lote"

          >
            <b-input-group size="md">
              <b-form-input
                  id="basicInput2"
                  type="search"
                  @search="resetFilterLots"
                  @keyup="searchForLots"
                  v-model="lots"
                  placeholder="Buscar"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
              striped
              hover
              responsive
              :current-page="currentPage"
              :items="items"
              :fields="fields"
          >

            <template #cell(actions)="data">
              <span v-b-tooltip.hover title="Visualizar" >
                 <router-link  :to="`/detalle_etiqueta/${data.value.id}`" style="color: darkblue">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="33" height="33"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                     stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"/>
</svg>
                     </router-link>
              </span>

              <span v-b-tooltip.hover title="Editar Etiqueta"  >
                   <router-link  :to="`/editar_etiqueta/${data.value.id}`" style="color: darkcyan">
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           class="icon icon-tabler icon-tabler-file-pencil" width="30"
                                           height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                           fill="none"
                                           stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/>
                      <path d="M10 18l5 -5a1.414 1.414 0 0 0 -2 -2l-5 5v2h2z"/>
                    </svg>
                   </router-link>
                </span>

              <span v-b-tooltip.hover title="Desactivar" v-if="data.value.is_active" style="color: darkgreen"
                    @click="changeStatus(data.value.id, 'delete')">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                       class="icon icon-tabler icon-tabler-square-check-filled"
                                       width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5"
                                       stroke="currentColor" fill="none"
                                       stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path
                        d="M18.333 2c1.96 0 3.56 1.537 3.662 3.472l.005 .195v12.666c0 1.96 -1.537 3.56 -3.472 3.662l-.195 .005h-12.666a3.667 3.667 0 0 1 -3.662 -3.472l-.005 -.195v-12.666c0 -1.96 1.537 -3.56 3.472 -3.662l.195 -.005h12.666zm-2.626 7.293a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                        stroke-width="0" fill="currentColor"/>
                  </svg>
              </span>
              <span v-b-tooltip.hover title="Activar" v-else style="color: darkred"
                    @click="changeStatus(data.value.id, 'active')">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   class="icon icon-tabler icon-tabler-square-rounded-x-filled"
                                   width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                   fill="none"
                                   stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path
                          d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                          fill="currentColor" stroke-width="0"/>
                    </svg>
              </span>


            </template>
          </b-table>
        </b-col>

        <b-col
            cols="12"
        >
          <b-pagination
              @change="changePage"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
          />
        </b-col>
      </b-row>

    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal, BFormCheckbox, BFormCheckboxGroup

} from 'bootstrap-vue'
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from 'vue-good-table'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "Index",
  components: {
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip, BModal,
    VBModal,
    ModalAppointment, flatPickr, BFormCheckbox, BFormCheckboxGroup, vSelect
  },
  data() {
    return {
      product_id: null,
      filter_product_id: null,
      optionsProducts: [],
      lots: null,
      brand: null,
      perPage: 10,
      show: false,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      searchTimeout: null,
      currentPage: 1,
      fields: [
        {
          key: 'consecutive', label: 'Consecutivo', sortable: true
        },
        {
          key: 'product', label: 'Producto'
        },
        {
          key: 'barcode', label: 'Etiqueta'
        },
        {
          key: 'brand', label: 'Marca'
        },
        {
          key: 'lots', label: 'Lote'
        }, {
          key: 'actions', label: 'Acciones',
        },
      ],
      items: [],
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  async created() {

    await this.findProducts();
    await this.initialize();
  },
  methods: {
    ...mapActions('pharmacy', ['findAllPharmacy', 'updateStatus']),
    ...mapActions('treatment', ['findProduct']),
    async changeStatus(id, type) {
      this.$swal({
        title: 'Desea continuar?',
        text: `Seguro que quieres ${type === 'delete' ? 'Desactivar' : 'Activar'}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.updateStatus({id, type});
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Editado!',
              text: 'Su etiqueta se edito correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.initialize();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al editar etiqueta',
                icon: 'error',
                variant: 'warning',
              },
            });
            this.show = false
          }


        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })
      this.show = true;

    },
    async changePage(page) {
      this.currentPage = page
      await this.initialize()

    },
    async searchForBrand() {
      if (this.brand) {
        if (this.brand.length >= 3) {
          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
          }
          this.searchTimeout = setTimeout(async() => {
            this.currentPage = 1;
            this.totalRows = null;
            await this.initialize()
          }, 300);

        }
      }else{
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(async() => {
          this.brand = null
          this.currentPage = 1;
          this.totalRows = null;
          await this.initialize()
        }, 300);

      }

    },
    async resetFilterBrand() {
      if (!this.brand) {
        this.currentPage = 1;
        this.totalRows = null;
        this.brand = null;
        await this.initialize();
      }
    },
    async searchForLots() {
      if (this.lots) {
        if (this.lots.length >= 3) {
          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
          }
          this.searchTimeout = setTimeout(async() => {
            this.currentPage = 1;
            this.totalRows = null;
            await this.initialize()
          }, 300);

        }
      }else{
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(async() => {
          this.lots = null
          this.currentPage = 1;
          this.totalRows = null;
          await this.initialize()
        }, 300);

      }

    },
    async resetFilterLots() {
      if (!this.lots) {
        this.currentPage = 1;
        this.totalRows = null;
        this.lots = null;
        await this.initialize();
      }
    },
    async searchByProduct() {
      this.product_id = this.filter_product_id ? this.filter_product_id.id : null;
      this.currentPage = 1;
      await this.initialize();


    },
    async initialize() {
      this.show = true
      const queryParams = {};
      if (this.currentPage > 1) {
        queryParams.page = this.currentPage
      } else {
        this.totalRows = null;
        this.currentPage = 1
        queryParams.page = 1
      }
      if (this.product_id) {
        queryParams.product_id = this.product_id
      }
      if (this.lots) {

        queryParams.lots = this.lots
      }
      if (this.brand) {
        queryParams.brand = this.brand
      }
      this.items = [];
      const queryString = Object.keys(queryParams)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
          .join('&');

      const response = await this.findAllPharmacy(queryString);
      if (response.data.length <= 0) {
        this.items = [];
        this.show = false
        return
      }
      this.totalRows = response.pagination.totalItems;
      for (const a of response.data) {
        this.items.push({
          consecutive: a.consecutive,
          product: a.ProductTemplate.name,
          barcode: a.barcode,
          brand: a.brand,
          lots: a.lots,
          actions: {
            id: a.id,
            is_active: a.is_active,
          }
        })
      }
      this.show = false;
      return
    },
    async findProducts() {
      const response = await this.findProduct();
      for (const a of response) {
        const de = a.default_code ? '(' + a.default_code + ')' : ''
        this.optionsProducts.push({
          id: a.id,
          value: a.name + ' ' + de
        })
      }
    },
  },

  computed: {
    sortOptions() {
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({text: f.label, value: f.key}))

      // Create an options list from our fields
    },
  },

}
</script>

<style scoped>

</style>